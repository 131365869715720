// #### ROW MANAGEMENT
export const RECOMPUTE_MODEL = 'engineMiddlewareSaga/RECOMPUTE_MODEL'
export const UPDATE_FORMULA = 'engineMiddlewareSaga/UPDATE_FORMULA'
export const CREATE_ROW = 'engineMiddlewareSaga/CREATE_ROW'
export const DELETE_ROWS = 'engineMiddlewareSaga/DELETE_ROWS'
export const PUBLISH_ROW = 'engineMiddlewareSaga/PUBLISH_ROW'
export const UPDATE_DATES = 'engineMiddlewareSaga/UPDATE_DATES'
export const CONNECT_TABLE = 'engineMiddlewareSaga/CONNECT_TABLE'
export const DISCONNECT_TABLE = 'engineMiddlewareSaga/DISCONNECT_TABLE'
export const CONNECT_VARIABLE = 'engineMiddlewareSaga/CONNECT_VARIABLE'
export const DISCONNECT_VARIABLE = 'engineMiddlewareSaga/DISCONNECT_VARIABLE'
export const CREATE_CATEGORY = 'engineMiddlewareSaga/CREATE_CATEGORY'
export const UPDATE_CATEGORY = 'engineMiddlewareSaga/UPDATE_CATEGORY'
export const REMOVE_CATEGORY = 'engineMiddlewareSaga/REMOVE_CATEGORY'
export const CREATE_RELATIONSHIP = 'engineMiddlewareSaga/CREATE_RELATIONSHIP'
export const UPDATE_RELATIONSHIP = 'engineMiddlewareSaga/UPDATE_RELATIONSHIP'
export const REMOVE_RELATIONSHIP = 'engineMiddlewareSaga/REMOVE_RELATIONSHIP'
export const UPDATE_ROW_LEVEL = 'engineMiddlewareSaga/UPDATE_ROW_LEVEL'
export const UPDATE_ROW_AGGR = 'engineMiddlewareSaga/UPDATE_ROW_AGGR'
export const UPDATE_CELL_OVERRIDES = 'engineMiddlewareSaga/UPDATE_CELL_OVERRIDES'
export const DELETE_CELL_OVERRIDES = 'engineMiddlewareSaga/DELETE_CELL_OVERRIDES'
export const DELETE_VAR_OVERRIDES = 'modelDataSaga/DELETE_VAR_OVERRIDES'
export const SIMULATION = 'modelEngineSaga/SIMULATION'
export const EXPORT_VARIABLE = 'modelEngineSaga/EXPORT_VARIABLE'
export const BUILD_SABANA = 'modelEngineSaga/BUILD_SABANA'

// #### ROW MANAGEMENT
export const recomputeModel = ({ tenantId, teamId, modelId, modelProps }) => {
	return { type: RECOMPUTE_MODEL, tenantId, teamId, modelId, modelProps }
}

export const connectTable = ({ tid, teamId, aid, id, content }) => {
	return { type: CONNECT_TABLE, tenantId: tid, teamId, modelId: aid, id, content }
}

export const disconnectTable = ({ tid, teamId, aid, id, tableId }) => {
	return { type: DISCONNECT_TABLE, tenantId: tid, teamId, modelId: aid, id, tableId }
}

export const connectVariable = ({ tid, teamId, aid, id, content }) => {
	return { type: CONNECT_VARIABLE, tenantId: tid, teamId, modelId: aid, id, content }
}

export const disconnectVariable = ({ tid, teamId, aid, id }) => {
	return { type: DISCONNECT_VARIABLE, tenantId: tid, teamId, modelId: aid, id }
}

export const updateFormula = ({ tid, teamId, aid, id, updates }) => {
	return { type: UPDATE_FORMULA, tenantId: tid, teamId, modelId: aid, id, updates }
}

export const createRow = ({ tid, teamId, aid, id }) => {
	return { type: CREATE_ROW, tenantId: tid, teamId, modelId: aid, id }
}

export const deleteRows = ({ tid, teamId, aid, deletes }) => {
	return { type: DELETE_ROWS, tenantId: tid, teamId, modelId: aid, deletes }
}

export const publishRow = ({ tid, teamId, aid, vid, publish }) => {
	return { type: PUBLISH_ROW, tenantId: tid, teamId, modelId: aid, id: vid, publish }
}

export const updateDates = ({ tid, teamId, aid, modelProps }) => {
	return { type: UPDATE_DATES, tenantId: tid, teamId, modelId: aid, modelProps }
}

export const createCategory = ({ tid, teamId, aid, id, messageKey, catProps, categoryId, prevTable, newTable }) => {
	return { type: CREATE_CATEGORY, tenantId: tid, teamId, modelId: aid, id, messageKey, catProps, categoryId, prevTable, newTable }
}

export const updateCategory = ({ tid, teamId, aid, id, messageKey, catProps, categoryId, prevTable, newTable }) => {
	return { type: UPDATE_CATEGORY, tenantId: tid, teamId, modelId: aid, id, messageKey, catProps, categoryId, prevTable, newTable }
}

export const removeCategory = ({ tid, teamId, aid, id, selectTable }) => {
	return { type: REMOVE_CATEGORY, tenantId: tid, teamId, modelId: aid, id, selectTable }
}

export const createRelationship = ({ tid, aid, id, messageKey, catProps, relationshipId, prevTable, newTable }) => {
	return { type: CREATE_RELATIONSHIP, tenantId: tid, modelId: aid, id, messageKey, catProps, relationshipId, prevTable, newTable }
}

export const updateRelationship = ({ tid, aid, id, messageKey, catProps, relationshipId, selectTable }) => {
	return { type: UPDATE_RELATIONSHIP, tenantId: tid, modelId: aid, id, messageKey, catProps, relationshipId, selectTable }
}

export const removeRelationship = ({ tid, aid, id, selectTable }) => {
	return { type: REMOVE_RELATIONSHIP, tenantId: tid, modelId: aid, id, selectTable }
}

export const updateRowLevel = ({ tid, aid, id, isConnected, categories }) => {
	return { type: UPDATE_ROW_LEVEL, tenantId: tid, modelId: aid, id, isConnected, level: categories }
}

export const updateRowAggr = ({ tid, aid, id, aggr }) => {
	return { type: UPDATE_ROW_AGGR, tenantId: tid, modelId: aid, id, aggr }
}

export const updateCellOverrides = ({ tid, aid, overrides }) => {
	return { type: UPDATE_CELL_OVERRIDES, tenantId: tid, modelId: aid, overrides }
}

export const deleteCellOverrides = ({ tid, teamId, aid, overrides }) => {
	return { type: DELETE_CELL_OVERRIDES, tenantId: tid, teamId, modelId: aid, overrides }
}

export const deleteVarOverrides = ({ tid, teamId, aid, id }) => {
	return { type: DELETE_VAR_OVERRIDES, tenantId: tid, teamId, modelId: aid, id }
}

export const setSimulation = ({ tid, aid, simulation }) => {
	return { type: SIMULATION, tenantId: tid, modelId: aid, simulation }
}

export const exportVariable = ({ tid, teamId, userId, aid, vid }) => {
	return { type: EXPORT_VARIABLE, tenantId: tid, teamId, userId, modelId: aid, vid }
}

export const buildSabana = ({ tid, teamId, aid }) => {
	return { type: BUILD_SABANA, tenantId: tid, teamId, modelId: aid }
}